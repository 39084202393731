import { h } from 'preact';
import htm from 'htm';

import { Band } from '../atoms/band';
import { Section, SectionHead, SectionNarrow } from '../molecules/section';
import getInvolvedHead from './getinvolved-head.jpg';

const html = htm.bind(h);

export default () => html`
	<${Section} banner backgroundImage=${getInvolvedHead}>
		<${SectionHead} text="Get Involved" shadow />
	</${Section}>
	<${Section}>
		<${SectionHead} text="ExhIbItors" />
		<${SectionNarrow} justify>
			<p>Are you interested in exhibiting with us in 2025? Exhibitor spaces for 2025 are <b><i>available now</i></b>! Contact <a href="mailto: stacey@norwichgames.uk">Stacey Sanderson</a> and we'll get back to you with an exhibitor information pack!</p>

			<p>In 2023 and 2024 we hosted around 60 exhibitors and received a fantastic response, and we'd love to have you join us for the THIRD YEAR of NGC!</p>
		</${SectionNarrow}>
	</${Section}>
	<${Section} alt>
		<${SectionHead} text="RPG Game Masters" />
		<${SectionNarrow} justify>
			<p>In 2025 we'll be hosting another big day of games, and if you'd like to run an RPG we'd love to have you with us! RPG Game Masters are the lifeblood of our events, so we give all RPG GMs free VIP entry to the convention.</p>

			<p>RPG event signups are <a href="https://forms.gle/3S1CWHqwVLh3GtJt8" target="_blank">now open</a>!</p>
		</${SectionNarrow}>
	</${Section}>
	<${Section}>
		<${SectionHead} text="Volunteers" />
		<${SectionNarrow} justify>
			<p>NGC is all about community, and our group of tightly-knit volunteers are the friendliest around. If you're interested in joining us to help make NGC a success please fill out <a href="https://forms.gle/CP5oScubPtYjdbXZ7" target="_blank">this form</a> and we'll be in touch! We ask that volunteers be willing to give around 6 hours of their time on the day, but it's unlikely we'll be asking for more than about 4 hours of light work. All volunteers will be paired with someone who has previously been involved with the organisation and running of Norwich Games Convention.</p>

			<p>Volunteers get VIP entry to the convention, exclusive swag, paid lunch, and anything else we can think of to say thankyou!</p>
		</${SectionNarrow}>
	</${Section}>
	<${Band} />
`;
