import { h } from 'preact';
import htm from 'htm';

import { palette } from '../branding';
import { Section, FloatingSection, SectionHead, SectionNarrow } from '../molecules/section';
import faqHead from './faq-head.jpg';

const html = htm.bind(h);

const qAndA = [{
	x: 'tickets-for-events',
	q: 'Do I need convention entry tickets for events?',
	a: () => html`<p>Yes. All events also require a General Entry ticket.</p>`,
}, {
	x: 'dogs',
	q: 'Can I bring my dog?',
	a: () => html`<p>The trade hall tends to get very busy, so we don't recommend bringing animals into these areas.</p>`,
}, {
	x: 'start-end',
	q: 'What time does the convention start and end?',
	a: () => html`<div>
		<p>Norwich Games Con 2025 will be taking place at <strong>Epic Studios (Magdalen Street) from 10am-11pm</strong> and the trade hall will be open until <strong>10am-5pm</strong>.</p>
	</div>`,
}, /* {
	x: 'ticket-prices',
	q: 'How much are tickets?',
	a: () => html`<div>
		<p>General entry tickets are online currently at £6 plus a small per-order booking fee, and will be available on the door at £7.</p>
		<p>Event tickets vary depending on the event, with many charity events asking for only what you can/are willing to give.</p>
	</div>`,
}, */ {
	x: 'children',
	q: 'Do children need tickets?',
	a: () => html`<p>Children ages 11 and under enter for free.</p>`,
}, {
	x: 'children-events',
	q: 'Are children welcome in role-playing games and other events?',
	a: () => html`<div>
		<p>All role-playing games will have an age limit given that can be seen when buying the tickets. Please check this before booking youngsters in. Most events will require a participating adult to be with children, especially those under 12 years of age.</p>
		<p>For the other live events, children are welcome, but please be aware that we can't promise a complete absence of strong language, particularly in our evening events.</p>
	</div>`,
}, {
	x: 'neurodiversity',
	q: 'Will there be a quiet room and/or other neurodiversity assistance available? ',
	a: () => html`<div>
		<p>We've worked with consultants to try and make the convention as accessible as possible for our neurodiverse friends!</p>
		<p>We have ear defenders and other useful items, and staff who are available to help with sensory overload and other issues on the day. Our door staff are perfectly placed to speak to about this, but feel free to approach any of our staff at any time and we'll be happy to help.</p>
	</div>`,
}, {
	x: 'submit-bring-and-buy',
	q: 'How does the Bring & Buy work?',
	a: () => html`<div>
		<p>For buyers, the Bring & Buy just works like a shop - turn up on the day and buy things you think look interesting. Because money needs to go to other attendees, however, we can only take payments in cash.</p>
	</div>`,
}, {
	x: 'carers-and-assistants',
	q: 'Is carer/assistant entry available?',
	a: () => html`<div>
		<p>We're able to offer free carer/assistant entry when accompanying an attendee who requires extra help - just speak with our staff when you arrive and they'll be happy to issue a carer/assistant ticket.</p>
	</div>`,
}].sort((a, b) => (a.q.replace(/^(The|A) /, '') > b.q.replace(/^(The|A) /, '') ? 1 : -1));

const QuestionLink = ({ text, link }) => {
	const style = {
		padding: 20,
		'border-radius': 10,
		'margin-top': 5,
		'margin-bottom': 5,
		'text-align': 'center',
		border: `2px solid ${palette.primary2}`,
		color: palette.primary2,
		width: '100%',
		'font-size': 20,
	};

	return html`<a href="#${link}" style=${style}>${text}</a>`;
};

const NavLink = ({ link = '#top', text = 'BACK TO TOP' }) => {
	const style = {
		background: palette.primary2,
		'border-radius': 10,
		width: 'fit-content',
		'margin-left': 'auto',
		'margin-right': 'auto',
	};
	const linkStyle = {
		color: palette.accent,
		display: 'block',
		padding: '10px 20px',
	};

	return html`<p style=${style}><center><a href=${link} style=${linkStyle}>${text}</a></center></p>`;
};

const Question = ({ text = '', alt }) => {
	const style = {
		color: alt ? palette.primary2 : palette.primary,
		'font-size': 32,
		width: '100%',
		'text-align': 'center',
		'font-weight': 'bolder',
	};
	return html`<div style=${style}>${text}</div> `;
};

export default () => html`
	<${Section} banner backgroundImage=${faqHead}>
		<${SectionHead} text="Frequently Asked Questions" shadow />
	</${Section}>
	<${Section} just id="top">
		${qAndA.map((item) => html`
			<${QuestionLink} link=${item.x} text=${item.q} />
		`)}
	</${Section}>
	${qAndA.map((item, n) => html`
		<${FloatingSection} id=${item.x} alt=${n % 2 == 0}>
			<${SectionNarrow} justify>
				<${Question} alt=${n % 2 == 0} text=${item.q} />
				<${item.a} />
				<${NavLink} alt=${n % 2 == 0} />
			</${SectionNarrow}>
		</${FloatingSection}>
	`)}
`;
